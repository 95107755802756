import React from 'react'
import './portfolio.css'
import IMGAI from '../../assets/ai-image.jpg'
import IMGWD from '../../assets/webdev.jpg'

const data = [

    {
        id: 1,
        image: IMGAI,
        title: "Fruit Freshness Classification",
        github: 'https://github.com/NoahOshana17/Fruit-Freshness-Classification/blob/main/fruit_classification.ipynb',
        demo: 'https://colab.research.google.com/github/NoahOshana17/Fruit-Freshness-Classification/blob/main/fruit_classification.ipynb'
    },

    {
        id: 2,
        image: IMGAI,
        title: "Smart Pantry Object Detection",
        github: 'https://github.com',
        demo: 'https://github.com'
    },

    {
        id: 3,
        image: IMGAI,
        title: "Loan Default Predictor",
        github: 'https://github.com/NoahOshana17/Loan-Default-Predictor',
        demo: 'https://colab.research.google.com/github/NoahOshana17/Loan-Default-Predictor/blob/main/Loan_Predictor.ipynb'
    },

    {
        id: 4,
        image: IMGWD,
        title: "Portfolio Web Development",
        github: 'https://github.com/NoahOshana17/Portfolio/tree/main/full-stack-portfolio',
        demo: 'https://noahoshana.com/'
    }

]

const Portfolio = () => {
    return (
        <section id="portfolio">
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>

            <div className="container portfolio__container">
                {
                    data.map(({ id, image, title, github, demo }) => {
                        return (
                            <article key={id} className="portfolio__item">
                                <div className="portfolio__item-image">
                                    <img src={image} alt={title} />
                                </div>
                                <h3>{title}</h3>
                                <div className="portfolio__item-cta">
                                    <a href={github} className='btn' target='_blank'>Github</a>
                                    <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
                                </div>
                            </article>
                        )
                    })
                }
            </div>

        </section>
    )
}

export default Portfolio