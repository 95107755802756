import React from 'react'
import Header from './components/header/header'
import Nav from './components/nav/nav'
import About from './components/about/about'
import Skills from './components/skills/skills'
import Awards from './components/awards/awards'
import Portfolio from './components/portfolio/portfolio'
//import Testimonials from './components/testimonials/testimonials'
import Contact from './components/contact/contact'
import Footer from './components/footer/footer'
/*import NewNav from './components/newnav/newnav'*/
// import NavBar from './components/navbar/navbar'
/*import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'*/

//deleted <Testimonials /> line 22
const App = () => {
    return (
        <>
            
            {/* <NavBar /> */}
            <Header />
            <Nav />
            <About />
            <Skills />
            <Awards />
            <Portfolio />
            
            <Contact />
            <Footer />

        </>
        )
}

export default App
