import React from 'react'
import './about.css'
import ME from '../../assets/2.JPEG'
import { FaAward } from 'react-icons/fa'
//import { FiUsers } from 'react-icons/fi'
import { HiFolder } from 'react-icons/hi'
import { IoSchoolSharp } from 'react-icons/io5'

const About = () => {
    return (
        <section id="about">
            <h5>Get To Know</h5>
            <h2>About Me</h2>

            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={ME} alt="About Image" />
                    </div>
                </div>

                <div className="about__content">
                    <div className="about__cards">

                        <article className="about__card">
                            <FaAward className="about__icon"/>
                            <h5>Experience</h5>
                            <small>2+ Years Working</small>
                        </article>

                        <article className="about__card">
                            <IoSchoolSharp className="about__icon" />
                            <h5>Education</h5>
                            <small>Baylor University</small>
                        </article>

                        <article className="about__card">
                            <HiFolder className="about__icon" />
                            <h5>Projects</h5>
                            <small>Various projects</small>
                        </article>

                    </div>

                    <p>
                        I have 2+ years of experience working with cutting-edge technologies. Through my work experience,
                        I have gained the necessary technical skills to drive software development and assist in the full Software Development Life Cycle as well as the soft skills
                        necessary for collaboration at the global level, being exposed to working environments that stretch from the United States to Asia to Europe. My ability to quickly learn and adjust
                        has allowed me to have the success I have had throughout the early stages of my career. 
                    </p>
                    <a href="#contact" className='btn btn-primary'>Let's Talk</a>
                </div>
            </div>
        </section>
        )
}

export default About