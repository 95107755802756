import React from 'react'
import './skills.css'
import { BsFillPatchCheckFill } from 'react-icons/bs'

const Skills = () => {
    return (
        <section id="skills">
            <h5>What Skills I Have</h5>
            <h2>My Skills</h2>

            <div className="container skills__container">
                <div className="skills__frontend">
                    <h3>Frontend Development</h3>
                    <div className="skills__content">
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon" />
                            <div>
                                <h4>HTML</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon"  />
                            <div>
                                <h4>CSS</h4>
                                <small className="text-light">Beginner</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon"  />
                            <div>
                                <h4>JavaScript</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon"  />
                            <div>
                                <h4>Bootstrap</h4>
                                <small className="text-light">Beginner</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon"  />
                            <div>
                                <h4>React</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                    </div>
                </div>
                <div className="skills__backend">
                    <h3>Backend Development</h3>
                    <div className="skills__content">
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon"  />
                            <div>
                                <h4>MySQL</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon"  />
                            <div>
                                <h4>NodeJS</h4>
                                <small className="text-light">Beginner</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon"  />
                            <div>
                                <h4>MongoDB</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon"  />
                            <div>
                                <h4>Python</h4>
                                <small className="text-light">Expert</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon"  />
                            <div>
                                <h4>PHP</h4>
                                <small className="text-light">Beginner</small>
                            </div>
                        </article>
                    </div>
                </div>
                <div className="skills__machine-learning">
                    <h3>Machine Learning</h3>
                    <div className="skills__content">
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon" />
                            <div>
                                <h4>Tensorflow</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon" />
                            <div>
                                <h4>Python</h4>
                                <small className="text-light">Expert</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon" />
                            <div>
                                <h4>Computer Vision</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon" />
                            <div>
                                <h4>Data Visualization</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon" />
                            <div>
                                <h4>Predictive Analysis</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                    </div>
                </div>
                <div className="skills__other">
                    <h3>Other Skills</h3>
                    <div className="skills__content">
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon" />
                            <div>
                                <h4>C++</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon" />
                            <div>
                                <h4>Git</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                        <article className="skills__details">
                            <BsFillPatchCheckFill className="skills__details-icon" />
                            <div>
                                <h4>Pandas</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>                     
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills