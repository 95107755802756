import React from 'react'
import './nav.css'
import { IoHome } from 'react-icons/io5'
import { RiContactsBook2Fill } from 'react-icons/ri'
import { MdOutlineWork } from 'react-icons/md'
import { RiMessage2Fill } from 'react-icons/ri'
import { RiServiceFill } from 'react-icons/ri'
import { useState } from 'react'
import { IoIosPaper } from 'react-icons/io'

const Nav = () => {
    const [activeNav, setActiveNav] = useState('#')
    return (
        <nav className="nav1">
            <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><IoHome /></a>
            <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''} > <RiContactsBook2Fill /></a>
            <a href="#skills" onClick={() => setActiveNav('#skills')} className={activeNav === '#skills' ? 'active' : ''}><IoIosPaper /></a>
            <a href="#awards" onClick={() => setActiveNav('#awards')} className={activeNav === '#awards' ? 'active' : ''}><RiServiceFill /></a>
            <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><MdOutlineWork /></a>
            <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><RiMessage2Fill /></a>
        </nav>
    )
}

export default Nav