import React from 'react'
import './footer.css'
import { GrInstagram } from 'react-icons/gr'

const Footer = () => {
    return (
        <footer>
            <a href="#" className="footer__logo">Oshana</a>

            <ul className="permalinks">
                <li><a href="#">Home</a></li>
                <li><a href="#">About</a></li>
                <li><a href="#">Skills</a></li>
                <li><a href="#">Portfolio</a></li>
                <li><a href="#">Testimonials</a></li>
                <li><a href="#">Contact</a></li>
            </ul>

            <div className="footer__socials">
                <a href="https://www.instagram.com/noahoshana/"><GrInstagram /></a>
            </div>

            <div className="footer__copyright">
                <small>&copy; Noah Oshana. All rights reserved.</small>
            </div>
        </footer>
    )
}

export default Footer