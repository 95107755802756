import React from 'react'
import './awards.css'
import { FaAward } from 'react-icons/fa'

const Awards = () => {
    return (
        <section id="awards">
            <h5>What I've Accomplished</h5>
            <h2>My Awards</h2>

            <div className="container awards__container">
                <div className="awards__content">
                    <div className="awards__cards">
                        <article className="awards__card">
                            <FaAward className="awards__icon" />
                            <h5>NXP 1st Place Internship Award Winner</h5>
                            <small>Voted 1st place by NXP's management staff at the end of summer presentations as a Machine Learning Software Engineer. </small>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Awards